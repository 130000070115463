import { CreatedFlowcodeHeapProperties } from '@dtx-company/true-common/src/types/heap'
import {
  DrawerTypes,
  FlowcodeDownloadOptionMimeType,
  LandingPageDestination
} from '@dtx-company/inter-app/src/types/flowcode'
import { GetAutoDesignsPartialsResponseType } from '@dtx-company/true-common/src/types/autoDesign'
import { StudioFlowcodeDownloadOptionMimeType } from '@dtx-company/true-common/src/types/studio'
import {
  fireBingEvent,
  fireGtmEvent,
  fireHeapEvent,
  fireOptimizelyEvent,
  getHeapBooleanParam
} from '../helpers'
import eventNames from '../constants/flowcode'

const e = {
  userDownloadedFlowcode(
    flowcodeId: string,
    fileType: FlowcodeDownloadOptionMimeType | StudioFlowcodeDownloadOptionMimeType
  ): void {
    const { userDownloadedFlowcode } = eventNames
    fireGtmEvent(userDownloadedFlowcode)
    fireHeapEvent(userDownloadedFlowcode, {
      flowcode_id: flowcodeId,
      fileType
    })
    fireBingEvent('DOWNLOAD', 'FLOWCODE')
  },
  userCreatedFlowcode(
    rulesCreated = false,
    flowcodeId: string,
    codeProperties?: CreatedFlowcodeHeapProperties,
    isFirstCode?: boolean
  ): void {
    const { userCreatedFlowcode } = eventNames
    fireGtmEvent(userCreatedFlowcode)
    fireHeapEvent(userCreatedFlowcode, {
      rulesCreated: getHeapBooleanParam(rulesCreated),
      flowcode_id: flowcodeId,
      ...codeProperties,
      isFirstCode
    })
    fireOptimizelyEvent(userCreatedFlowcode)
  },
  userChoseFlowcodeColor(
    color: string,
    autodesign?: boolean,
    path?: string,
    variant?: string | null
  ): void {
    const { userChoseFlowcodeColor } = eventNames
    fireHeapEvent(userChoseFlowcodeColor, {
      color,
      autodesign: getHeapBooleanParam(autodesign),
      path,
      variant
    })
  },
  userAddedCenterFlowcodeImage(autodesign?: boolean, variant?: string | null): void {
    const { userAddedCenterFlowcodeImage } = eventNames
    fireHeapEvent(userAddedCenterFlowcodeImage, {
      autodesign: getHeapBooleanParam(autodesign),
      variant
    })
  },
  userClickedAutoDesignButton(path?: string): void {
    const { userClickedAutoDesignButton } = eventNames
    fireHeapEvent(userClickedAutoDesignButton, { path })
  },
  userRemovedAutoDesignColor(color: string, path?: string): void {
    const { userRemovedAutoDesignColor } = eventNames
    fireHeapEvent(userRemovedAutoDesignColor, { color, path })
  },
  userClickedOnAutoDesignCode(design: GetAutoDesignsPartialsResponseType, path?: string): void {
    const { userClickedOnAutoDesignCode } = eventNames
    fireHeapEvent(userClickedOnAutoDesignCode, {
      configuration: design.configuration,
      innerPartial: design.ids[0],
      outerPartial: design.ids[1],
      path
    })
  },
  userCreatedAutoDesignCode_0(flowcodeId: string, configuration: string, path?: string): void {
    const { userSelectedAutoDesignCode } = eventNames
    fireHeapEvent(userSelectedAutoDesignCode, {
      flowcodeId,
      configuration: configuration,
      path
    })
  },
  userCreatedAutoDesignCode_1(flowcodeId: string, autoCodesData: string[], path?: string): void {
    const { userSelectedAutoDesignCode } = eventNames
    fireHeapEvent(userSelectedAutoDesignCode, {
      flowcodeId,
      unused0: autoCodesData[0],
      unused1: autoCodesData[1],
      unused2: autoCodesData[2],
      path
    })
  },
  userCreatedAutoDesignCode_2(flowcodeId: string, autoCodesData: string[], path?: string): void {
    const { userSelectedAutoDesignCode } = eventNames
    fireHeapEvent(userSelectedAutoDesignCode, {
      flowcodeId,
      unused3: autoCodesData[0],
      unused4: autoCodesData[1],
      path
    })
  },
  userToggledDrawer(drawerType: DrawerTypes): void {
    const { userToggledDrawer } = eventNames
    fireHeapEvent(userToggledDrawer, { drawerType: drawerType })
  },
  userHoveredOverCustomAssignedCodes(): void {
    const { userHoveredOverCustomAssignedCodes } = eventNames
    fireHeapEvent(userHoveredOverCustomAssignedCodes)
  },
  userSelectedFlowcodeTheme(theme: string): void {
    const { userSelectedFlowcodeTheme } = eventNames
    fireHeapEvent(userSelectedFlowcodeTheme, {
      theme
    })
  },
  userSelectedFlowcodeBorder(border: string): void {
    const { userSelectedFlowcodeBorder } = eventNames
    fireHeapEvent(userSelectedFlowcodeBorder, {
      border
    })
  },
  userDuplicatedFlowcode(flowcodeId: string): void {
    const { userDuplicatedFlowcode } = eventNames
    fireHeapEvent(userDuplicatedFlowcode, {
      flowcodeId
    })
  },
  userSelectedFlowcodeTemplate(template: string, variant?: string | null): void {
    const { userSelectedFlowcodeTemplate } = eventNames
    fireHeapEvent(userSelectedFlowcodeTemplate, {
      template,
      variant
    })
  },
  userSortedCodesBy(sortType: string): void {
    const { userSortedCodesBy } = eventNames
    fireHeapEvent(userSortedCodesBy, {
      sortType
    })
  },
  userViewedCodeManagementDemo(): void {
    const { userViewedCodeManagementDemo } = eventNames
    fireHeapEvent(userViewedCodeManagementDemo)
  },
  userViewedEditCodeDesignPage(): void {
    const { userViewedEditCodeDesignPage } = eventNames
    fireHeapEvent(userViewedEditCodeDesignPage)
  },
  userSelectedFlowcodeDestination(label: string, id: LandingPageDestination): void {
    const { userSelectedFlowcodeDestination } = eventNames
    fireHeapEvent(userSelectedFlowcodeDestination, {
      label,
      id
    })
  },
  userClickedTVCodeToggle(action: string): void {
    const { userClickedTVCodeToggle } = eventNames
    fireHeapEvent(userClickedTVCodeToggle, { action })
  },
  userClickedRemoveFlowcodeEyeBrandingToggle(action: string): void {
    const { userClickedRemoveFlowcodeEyeBrandingToggle } = eventNames
    fireHeapEvent(userClickedRemoveFlowcodeEyeBrandingToggle, { action })
  },
  userClickedGatedBulkCodeCreation(): void {
    const { userClickedGatedBulkCodeCreation } = eventNames
    fireHeapEvent(userClickedGatedBulkCodeCreation)
  },
  userClickedGatedSmartRules(): void {
    const { userClickedGatedSmartRules } = eventNames
    fireHeapEvent(userClickedGatedSmartRules)
  },
  userClickedGatedAdditionalColors(): void {
    const { userClickedGatedAdditionalColors } = eventNames
    fireHeapEvent(userClickedGatedAdditionalColors)
  },
  Flowcode_Combined_Generator_Clicked_Watch_Tutorial(): void {
    const { Flowcode_Combined_Generator_Clicked_Watch_Tutorial } = eventNames
    fireHeapEvent(Flowcode_Combined_Generator_Clicked_Watch_Tutorial)
  },
  Flowcode_Combined_Generator_Clicked_Color(args: { color: string }): void {
    const { Flowcode_Combined_Generator_Clicked_Color } = eventNames
    fireHeapEvent(Flowcode_Combined_Generator_Clicked_Color, { color: args.color })
  },
  Flowcode_Combined_Generator_Clicked_Pattern(args: { pattern: string }): void {
    const { Flowcode_Combined_Generator_Clicked_Pattern } = eventNames
    fireHeapEvent(Flowcode_Combined_Generator_Clicked_Pattern, { pattern: args.pattern })
  },
  Flowcode_Combined_Generator_Clicked_Title(): void {
    const { Flowcode_Combined_Generator_Clicked_Title } = eventNames
    fireHeapEvent(Flowcode_Combined_Generator_Clicked_Title)
  },
  Flowcode_CombinedGenerator_Upload_Image(): void {
    const { Flowcode_CombinedGenerator_Upload_Image } = eventNames
    fireHeapEvent(Flowcode_CombinedGenerator_Upload_Image)
  },
  Flowcode_Combined_Generator_Clicked_Flowpage_Preview(): void {
    const { Flowcode_Combined_Generator_Clicked_Flowpage_Preview } = eventNames
    fireHeapEvent(Flowcode_Combined_Generator_Clicked_Flowpage_Preview)
  },

  Flowcode_Combined_Generator_Entered_Destination_Input(type: string): void {
    const { Flowcode_Combined_Generator_Entered_Destination_Input } = eventNames
    fireHeapEvent(Flowcode_Combined_Generator_Entered_Destination_Input, { type })
  },
  Flowcode_Combined_Generator_Clicked_Copy_Flowpage(): void {
    const { Flowcode_Combined_Generator_Clicked_Copy_Flowpage } = eventNames
    fireHeapEvent(Flowcode_Combined_Generator_Clicked_Copy_Flowpage)
  },
  Flowcode_Combined_Generator_Clicked_Print_Code(): void {
    const { Flowcode_Combined_Generator_Clicked_Print_Code } = eventNames
    fireHeapEvent(Flowcode_Combined_Generator_Clicked_Print_Code)
  },
  Flowcode_Combined_Generator_Clicked_Email_Code(): void {
    const { Flowcode_Combined_Generator_Clicked_Email_Code } = eventNames
    fireHeapEvent(Flowcode_Combined_Generator_Clicked_Email_Code)
  },
  Flowcode_Combined_Generator_Clicked_Manage_Code(): void {
    const { Flowcode_Combined_Generator_Clicked_Manage_Code } = eventNames
    fireHeapEvent(Flowcode_Combined_Generator_Clicked_Manage_Code)
  },
  Flowcode_Combined_Generator_Clicked_Code_Only(): void {
    const { Flowcode_Combined_Generator_Clicked_Code_Only } = eventNames
    fireHeapEvent(Flowcode_Combined_Generator_Clicked_Code_Only)
  },
  /**
   * @deprecated
   * userStartedProConversion with lockSelected will fire automatically when the code limit
   * experience is opened, can remove any instances of this heap event
   * https://flowcode.slack.com/archives/C02MFLX38P6/p1688670315885169?thread_ts=1688670054.863149&cid=C02MFLX38P6
   */
  Flowcode_Core_Management_Hit_Code_Limit(args: { upsellPlan: string; sourceId: string }): void {
    fireHeapEvent(eventNames.Flowcode_Core_Management_Hit_Code_Limit, {
      upsellPlan: args.upsellPlan,
      sourceId: args.sourceId
    })
  },
  Flowcode_Code_Download_Downloaded_Best_Practices_PDF(): void {
    const { Flowcode_Code_Download_Downloaded_Best_Practices_PDF } = eventNames
    fireHeapEvent(Flowcode_Code_Download_Downloaded_Best_Practices_PDF)
  },
  Flowcode_Code_Management_Clicked_Custom_Code_Upsell_Card(): void {
    const { Flowcode_Code_Management_Clicked_Custom_Code_Upsell_Card } = eventNames
    fireHeapEvent(Flowcode_Code_Management_Clicked_Custom_Code_Upsell_Card)
  },
  Flowcode_CombinedGenerator_Signup(args: { destinationQty: number }): void {
    const { Flowcode_CombinedGenerator_Signup } = eventNames
    fireHeapEvent(Flowcode_CombinedGenerator_Signup, { destinationQty: args.destinationQty })
  },
  Flowcode_Homepage_Clicked_ShapeOption(shapeOption?: string): void {
    fireHeapEvent(eventNames.Flowcode_Homepage_Clicked_ShapeOption, { shapeOption })
  },
  Flowcode_CombinedGenerator_DownloadCode(args: { codeOnly: boolean }): void {
    const { Flowcode_CombinedGenerator_DownloadCode } = eventNames
    fireHeapEvent(Flowcode_CombinedGenerator_DownloadCode, { codeOnly: args.codeOnly })
  },
  Flowcode_Management_Clicked_BrowseTemplatesButton(args: { batchId: string }): void {
    const { Flowcode_Management_Clicked_BrowseTemplatesButton } = eventNames
    fireHeapEvent(Flowcode_Management_Clicked_BrowseTemplatesButton, { batchId: args.batchId })
  },
  Core_Homepage_Inputted_CodeDestination(params: {
    destinationType: string
    destinationValue: string
  }): void {
    const { Core_Homepage_Inputted_CodeDestination } = eventNames
    fireHeapEvent(Core_Homepage_Inputted_CodeDestination, params)
  },
  Flowcode_Generator_Clicked_ProUpsellFreeTextRim(): void {
    const { Flowcode_Generator_Clicked_ProUpsellFreeTextRim } = eventNames
    fireHeapEvent(Flowcode_Generator_Clicked_ProUpsellFreeTextRim)
  },
  Flowcode_Generator_Clicked_ProUpsellFreeTextCenter(): void {
    const { Flowcode_Generator_Clicked_ProUpsellFreeTextCenter } = eventNames
    fireHeapEvent(Flowcode_Generator_Clicked_ProUpsellFreeTextCenter)
  },
  Flowcode_LoggedIn_Generator_Clicked_ProUpsellHexColors(): void {
    fireHeapEvent(eventNames.Flowcode_LoggedIn_Generator_Clicked_ProUpsellHexColors)
  },
  Flowcode_LoggedIn_Generator_Selected_FlowcodeColor(
    selectedColor: string,
    colorSelectedVia: string,
    colorAppliedTo: string
  ): void {
    fireHeapEvent(eventNames.Flowcode_LoggedIn_Generator_Selected_FlowcodeColor, {
      selectedColor,
      colorSelectedVia,
      colorAppliedTo
    })
  },
  Flowcode_Code_Management_Clicked_Flowcode_FAQs_btn(): void {
    const { Flowcode_Code_Management_Clicked_Flowcode_FAQs_btn } = eventNames
    fireHeapEvent(Flowcode_Code_Management_Clicked_Flowcode_FAQs_btn)
  },
  Flowcode_Code_Details_Clicked_Flowcode_FAQs_btn(): void {
    const { Flowcode_Code_Details_Clicked_Flowcode_FAQs_btn } = eventNames
    fireHeapEvent(Flowcode_Code_Details_Clicked_Flowcode_FAQs_btn)
  },
  Flowcode_Code_Details_Clicked_PrintWithCanvaButton(): void {
    const { Flowcode_Code_Details_Clicked_PrintWithCanvaButton } = eventNames
    fireHeapEvent(Flowcode_Code_Details_Clicked_PrintWithCanvaButton)
  },
  Flowcode_Code_Details_Print_Dropdown_Clicked_ViewStoreItems(batchId?: string): void {
    const { Flowcode_Code_Details_Print_Dropdown_Clicked_ViewStoreItems } = eventNames
    fireHeapEvent(Flowcode_Code_Details_Print_Dropdown_Clicked_ViewStoreItems, { batchId })
  },
  Flowcode_Code_Details_Print_Dropdown_Clicked_CustomizeWithTemplates(batchId?: string): void {
    const { Flowcode_Code_Details_Print_Dropdown_Clicked_CustomizeWithTemplates } = eventNames
    fireHeapEvent(Flowcode_Code_Details_Print_Dropdown_Clicked_CustomizeWithTemplates, { batchId })
  },
  Core_Flowcode_Clicked_Explore_Print_Store_Button(): void {
    const { Core_Flowcode_Clicked_Explore_Print_Store_Button } = eventNames
    fireHeapEvent(Core_Flowcode_Clicked_Explore_Print_Store_Button)
  },
  Flowcode_Logged_in_Generator_Clicked_Dropdown_Redirected_Link_Domain(): void {
    const { Flowcode_Logged_in_Generator_Clicked_Dropdown_Redirected_Link_Domain } = eventNames
    fireHeapEvent(Flowcode_Logged_in_Generator_Clicked_Dropdown_Redirected_Link_Domain)
  },
  Flowcode_Logged_In_Generator_Clicked_Premade_Templated_Design({
    templateType
  }: {
    templateType: string
  }): void {
    const { Flowcode_Logged_In_Generator_Clicked_Premade_Templated_Design } = eventNames
    fireHeapEvent(Flowcode_Logged_In_Generator_Clicked_Premade_Templated_Design, { templateType })
  },
  Flowcode_Logged_In_Generator_Clicked_Pattern({ patternType }: { patternType: string }): void {
    const { Flowcode_Logged_In_Generator_Clicked_Pattern } = eventNames
    fireHeapEvent(Flowcode_Logged_In_Generator_Clicked_Pattern, { patternType })
  },
  Flowcode_Logged_In_Generator_Typed_Center_Text(): void {
    const { Flowcode_Logged_In_Generator_Typed_Center_Text } = eventNames
    fireHeapEvent(Flowcode_Logged_In_Generator_Typed_Center_Text)
  },
  Flowcode_Logged_In_Generator_Clicked_Upload_Image_File(): void {
    const { Flowcode_Logged_In_Generator_Clicked_Upload_Image_File } = eventNames
    fireHeapEvent(Flowcode_Logged_In_Generator_Clicked_Upload_Image_File)
  },
  Flowcode_Logged_In_Generator_Typed_Frame_Top_Text(): void {
    const { Flowcode_Logged_In_Generator_Typed_Frame_Top_Text } = eventNames
    fireHeapEvent(Flowcode_Logged_In_Generator_Typed_Frame_Top_Text)
  },
  Flowcode_Logged_In_Generator_Typed_Frame_Bottom_Text(): void {
    const { Flowcode_Logged_In_Generator_Typed_Frame_Bottom_Text } = eventNames
    fireHeapEvent(Flowcode_Logged_In_Generator_Typed_Frame_Bottom_Text)
  },
  Flowcode_Logged_In_Generator_Toggled_Photo_Reposition(): void {
    const { Flowcode_Logged_In_Generator_Toggled_Photo_Reposition } = eventNames
    fireHeapEvent(Flowcode_Logged_In_Generator_Toggled_Photo_Reposition)
  },
  Flowcode_Logged_In_Generator_Photo_Zoom(): void {
    const { Flowcode_Logged_In_Generator_Photo_Zoom } = eventNames
    fireHeapEvent(Flowcode_Logged_In_Generator_Photo_Zoom)
  },
  Flowcode_Logged_In_Generator_Clicked_Upload_Image_File_Change(): void {
    const { Flowcode_Logged_In_Generator_Clicked_Upload_Image_File_Change } = eventNames
    fireHeapEvent(Flowcode_Logged_In_Generator_Clicked_Upload_Image_File_Change)
  },
  Flowcode_Logged_In_Generator_Clicked_Upload_Image_Save(): void {
    const { Flowcode_Logged_In_Generator_Clicked_Upload_Image_Save } = eventNames
    fireHeapEvent(Flowcode_Logged_In_Generator_Clicked_Upload_Image_Save)
  },
  Flowcode_Logged_In_Generator_Clicked_Upload_Image_Delete(): void {
    const { Flowcode_Logged_In_Generator_Clicked_Upload_Image_Delete } = eventNames
    fireHeapEvent(Flowcode_Logged_In_Generator_Clicked_Upload_Image_Delete)
  },
  Flowcode_Logged_In_Generator_Clicked_Share_Input(): void {
    const { Flowcode_Logged_In_Generator_Clicked_Share_Input } = eventNames
    fireHeapEvent(Flowcode_Logged_In_Generator_Clicked_Share_Input)
  },
  Flowcode_Logged_In_Generator_Clicked_Edit_Share_Permission(): void {
    const { Flowcode_Logged_In_Generator_Clicked_Edit_Share_Permission } = eventNames
    fireHeapEvent(Flowcode_Logged_In_Generator_Clicked_Edit_Share_Permission)
  },
  Flowcode_Logged_In_Generator_Typed_Flowcodes_Folder_Name(): void {
    const { Flowcode_Logged_In_Generator_Typed_Flowcodes_Folder_Name } = eventNames
    fireHeapEvent(Flowcode_Logged_In_Generator_Typed_Flowcodes_Folder_Name)
  },
  Flowcode_Product_Details_Clicked_Design_With_Our_Templates_Btn(productId: string): void {
    const { Flowcode_Product_Details_Clicked_Design_With_Our_Templates_Btn } = eventNames
    fireHeapEvent(Flowcode_Product_Details_Clicked_Design_With_Our_Templates_Btn, { productId })
  },
  Flowcode_LoggedInGenerator_Clicked_LockedRedirectDomainDropdown(): void {
    fireHeapEvent(eventNames.Flowcode_LoggedInGenerator_Clicked_LockedRedirectDomainDropdown)
  },
  Flowcode_LoggedInGenerator_Edited_Scan_Destination(): void {
    fireHeapEvent(eventNames.Flowcode_LoggedInGenerator_Edited_Scan_Destination)
  },
  Flowcode_LoggedInGenerator_Clicked_Save_Changes(): void {
    fireHeapEvent(eventNames.Flowcode_LoggedInGenerator_Clicked_Save_Changes)
  },
  Flowcode_Logged_In_Generator_Clicked_Create(): void {
    const { Flowcode_Logged_In_Generator_Clicked_Create } = eventNames
    fireHeapEvent(Flowcode_Logged_In_Generator_Clicked_Create)
  },
  Flowcode_Logged_In_Generator_Clicked_Create_And_Download(): void {
    const { Flowcode_Logged_In_Generator_Clicked_Create_And_Download } = eventNames
    fireHeapEvent(Flowcode_Logged_In_Generator_Clicked_Create_And_Download)
  },
  Flowcode_Logged_In_Generator_Clicked_Create_And_Print(): void {
    const { Flowcode_Logged_In_Generator_Clicked_Create_And_Print } = eventNames
    fireHeapEvent(Flowcode_Logged_In_Generator_Clicked_Create_And_Print)
  },
  Flowcode_Logged_In_Generator_Clicked_Create_And_Put_On_A_Flyer_Template(): void {
    const { Flowcode_Logged_In_Generator_Clicked_Create_And_Put_On_A_Flyer_Template } = eventNames
    fireHeapEvent(Flowcode_Logged_In_Generator_Clicked_Create_And_Put_On_A_Flyer_Template)
  },
  Flowcode_Logged_In_Generator_Clicked_Create_And_Put_On_A_Business_Card(): void {
    const { Flowcode_Logged_In_Generator_Clicked_Create_And_Put_On_A_Business_Card } = eventNames
    fireHeapEvent(Flowcode_Logged_In_Generator_Clicked_Create_And_Put_On_A_Business_Card)
  },
  Flowcode_Logged_In_Generator_Clicked_Create_And_Put_On_A_Template(): void {
    const { Flowcode_Logged_In_Generator_Clicked_Create_And_Put_On_A_Template } = eventNames
    fireHeapEvent(Flowcode_Logged_In_Generator_Clicked_Create_And_Put_On_A_Template)
  },
  Flowcode_Management_Clicked_TemplatesButton({
    product,
    location,
    batchId
  }: {
    product: string
    location: 'Code Details Dropdown' | 'Code Details Template CTA'
    batchId: string
  }) {
    const { Flowcode_Management_Clicked_TemplatesButton } = eventNames
    fireHeapEvent(Flowcode_Management_Clicked_TemplatesButton, {
      product,
      location,
      batchId
    })
  },
  Flowcode_Clicked_CompleteStateTab(label: string): void {
    const { Flowcode_Clicked_CompleteStateTab } = eventNames
    fireHeapEvent(Flowcode_Clicked_CompleteStateTab, { label })
  }
}
export default e
