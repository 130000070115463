export default {
  userDownloadedFlowcode: 'userDownloadedFlowcode',
  userUpdatedFlowcodeDesign: 'userUpdatedFlowcodeDesign',
  userCreatedFlowcode: 'userCreatedFlowcode',
  userChoseFlowcodeColor: 'userChoseFlowcodeColor',
  userAddedCenterFlowcodeImage: 'userAddedCenterFlowcodeImage',
  userClickedAutoDesignButton: 'userClickedAutoDesignButton',
  userRemovedAutoDesignColor: 'userRemovedAutoDesignColor',
  userClickedOnAutoDesignCode: 'userClickedOnAutoDesignCode',
  userSelectedAutoDesignCode: 'userSelectedAutoDesignCode',
  userToggledDrawer: 'userToggledDrawer',
  userHoveredOverCustomAssignedCodes: 'userHoveredOverCustomAssignedCodes',
  userSelectedFlowcodeTheme: 'userSelectedFlowcodeTheme',
  userSelectedFlowcodeBorder: 'userSelectedFlowcodeBorder',
  userDuplicatedFlowcode: 'userDuplicatedFlowcode',
  userSelectedFlowcodeTemplate: 'userSelectedFlowcodeTemplate',
  userSortedCodesBy: 'userSortedCodesBy',
  userViewedCodeManagementDemo: 'userViewedCodeManagementDemo',
  userViewedEditCodeDesignPage: 'userViewedEditCodeDesignPage',
  userSelectedFlowcodeDestination: 'userSelectedFlowcodeDestination',
  userClickedTVCodeToggle: 'userClickedTVCodeToggle',
  userClickedRemoveFlowcodeEyeBrandingToggle: 'userClickedRemoveFlowcodeEyeBrandingToggle',
  userClickedGatedBulkCodeCreation: 'userClickedGatedBulkCodeCreation',
  userClickedGatedSmartRules: 'userClickedGatedSmartRules',
  userClickedGatedAdditionalColors: 'userClickedGatedAdditionalColors',
  userClickedEnterpriseUpsellCard: 'userClickedEnterpriseUpsellCard',
  Flowcode_Combined_Generator_Clicked_Watch_Tutorial:
    'Flowcode_Combined_Generator_Clicked_Watch_Tutorial',
  Flowcode_Combined_Generator_Clicked_Color: 'Flowcode_Combined_Generator_Clicked_Color',
  Flowcode_Combined_Generator_Entered_Destination_Input:
    'Flowcode_Combined_Generator_Entered_Destination_Input',
  Flowcode_Combined_Generator_Clicked_Flowpage_Preview:
    'Flowcode_Combined_Generator_Clicked_Flowpage_Preview',
  Flowcode_Combined_Generator_Clicked_Title: 'Flowcode_Combined_Generator_Clicked_Title',
  Flowcode_Combined_Generator_Clicked_Copy_Flowpage:
    'Flowcode_Combined_Generator_Clicked_Copy_Flowpage',
  Flowcode_Combined_Generator_Clicked_Customize_Flowpage:
    'Flowcode_Combined_Generator_Clicked_Customize_Flowpage',
  Flowcode_Combined_Generator_Clicked_Print_Code: 'Flowcode_Combined_Generator_Clicked_Print_Code',
  Flowcode_Combined_Generator_Clicked_Email_Code: 'Flowcode_Combined_Generator_Clicked_Email_Code',
  Flowcode_Combined_Generator_Clicked_Manage_Code:
    'Flowcode_Combined_Generator_Clicked_Manage_Code',
  Flowcode_Combined_Generator_Clicked_Code_Only: 'Flowcode_Combined_Generator_Clicked_Code_Only',
  Flowcode_Core_Management_Hit_Code_Limit: 'Flowcode_Core_Management_Hit_Code_Limit',
  Flowcode_Code_Download_Downloaded_Best_Practices_PDF:
    'Flowcode_Code_Download_Downloaded_Best_Practices_PDF',
  Flowcode_Code_Management_Clicked_Custom_Code_Upsell_Card:
    'Flowcode_Code_Management_Clicked_Custom_Code_Upsell_Card',
  Flowcode_CombinedGenerator_Signup: 'Flowcode_CombinedGenerator_Signup',
  Flowcode_Homepage_Clicked_ShapeOption: 'Flowcode_Homepage_Clicked_ShapeOption',
  Flowcode_Combined_Generator_Clicked_Pattern: 'Flowcode_Combined_Generator_Clicked_Pattern',
  Flowcode_CombinedGenerator_Upload_Image: 'Flowcode_CombinedGenerator_Upload_Image',
  Flowcode_CombinedGenerator_DownloadCode: 'Flowcode_CombinedGenerator_DownloadCode',
  Flowcode_Management_Clicked_BrowseTemplatesButton:
    'Flowcode_Management_Clicked_BrowseTemplatesButton',
  Flowcode_Management_Clicked_TemplatesButton: 'Flowcode_Management_Clicked_TemplatesButton',
  Core_Homepage_Inputted_CodeDestination: 'Core_Homepage_Inputted_CodeDestination',
  Flowcode_Generator_Clicked_ProUpsellFreeTextRim:
    'Flowcode_Generator_Clicked_ProUpsellFreeTextRim',
  Flowcode_Generator_Clicked_ProUpsellFreeTextCenter:
    'Flowcode_Generator_Clicked_ProUpsellFreeTextCenter',
  Flowcode_LoggedIn_Generator_Clicked_ProUpsellHexColors:
    'Flowcode_LoggedIn_Generator_Clicked_ProUpsellHexColors',
  Flowcode_LoggedIn_Generator_Selected_FlowcodeColor:
    'Flowcode_LoggedIn_Generator_Selected_FlowcodeColor',
  Flowcode_Code_Management_Clicked_Flowcode_FAQs_btn:
    'Flowcode_Code_Management_Clicked_Flowcode_FAQs_btn',
  Flowcode_Code_Details_Clicked_Flowcode_FAQs_btn:
    'Flowcode_Code_Details_Clicked_Flowcode_FAQs_btn',
  Flowcode_Code_Details_Clicked_PrintWithCanvaButton:
    'Flowcode_Code_Details_Clicked_PrintWithCanvaButton',
  Flowcode_Code_Details_Print_Dropdown_Clicked_ViewStoreItems:
    'Flowcode_Code_Details_Print_Dropdown_Clicked_ViewStoreItems',
  Flowcode_Code_Details_Print_Dropdown_Clicked_CustomizeWithTemplates:
    'Flowcode_Code_Details_Print_Dropdown_Clicked_CustomizeWithTemplates',
  Core_Flowcode_Clicked_Explore_Print_Store_Button:
    'Core_Flowcode_Clicked_Explore_Print_Store_Button',
  Flowcode_Logged_in_Generator_Clicked_Dropdown_Redirected_Link_Domain:
    'Flowcode_Logged_in_Generator_Clicked_Dropdown_Redirected_Link_Domain',
  Flowcode_Logged_In_Generator_Clicked_Premade_Templated_Design:
    'Flowcode_Logged_In_Generator_Clicked_Premade_Templated_Design',
  Flowcode_Logged_In_Generator_Clicked_Pattern: 'Flowcode_Logged_In_Generator_Clicked_Pattern',
  Flowcode_Logged_In_Generator_Typed_Center_Text: 'Flowcode_Logged_In_Generator_Typed_Center_Text',
  Flowcode_Logged_In_Generator_Clicked_Upload_Image_File:
    'Flowcode_Logged_In_Generator_Clicked_Upload_Image_File',
  Flowcode_Logged_In_Generator_Typed_Frame_Top_Text:
    'Flowcode_Logged_In_Generator_Typed_Frame_Top_Text',
  Flowcode_Logged_In_Generator_Typed_Frame_Bottom_Text:
    'Flowcode_Logged_In_Generator_Typed_Frame_Bottom_Text',
  Flowcode_Logged_In_Generator_Toggled_Photo_Reposition:
    'Flowcode_Logged_In_Generator_Toggled_Photo_Reposition',
  Flowcode_Logged_In_Generator_Clicked_Upload_Image_File_Change:
    'Flowcode_Logged_In_Generator_Clicked_Upload_Image_File_Change',
  Flowcode_Logged_In_Generator_Clicked_Upload_Image_Save:
    'Flowcode_Logged_In_Generator_Clicked_Upload_Image_Save',
  Flowcode_Logged_In_Generator_Clicked_Upload_Image_Delete:
    'Flowcode_Logged_In_Generator_Clicked_Upload_Image_Delete',
  Flowcode_Logged_In_Generator_Clicked_Share_Input:
    'Flowcode_Logged_In_Generator_Clicked_Share_Input',
  Flowcode_Logged_In_Generator_Clicked_Edit_Share_Permission:
    'Flowcode_Logged_In_Generator_Clicked_Edit_Share_Permission',
  Flowcode_Logged_In_Generator_Typed_Flowcodes_Folder_Name:
    'Flowcode_Logged_In_Generator_Typed_Flowcodes_Folder_Name',
  Flowcode_Logged_In_Generator_Photo_Zoom: 'Flowcode_Logged_In_Generator_Photo_Zoom',
  Flowcode_Product_Details_Clicked_Design_With_Our_Templates_Btn:
    'Flowcode_Product_Details_Clicked_Design_With_Our_Templates_Btn',
  Flowcode_LoggedInGenerator_Clicked_LockedRedirectDomainDropdown:
    'Flowcode_LoggedInGenerator_Clicked_LockedRedirectDomainDropdown',
  Flowcode_LoggedInGenerator_Edited_Scan_Destination:
    'Flowcode_LoggedInGenerator_Edited_Scan_Destination',
  Flowcode_LoggedInGenerator_Clicked_Save_Changes:
    'Flowcode_LoggedInGenerator_Clicked_Save_Changes',
  Flowcode_Logged_In_Generator_Clicked_Create: 'Flowcode_Logged_In_Generator_Clicked_Create',
  Flowcode_Logged_In_Generator_Clicked_Create_And_Download:
    'Flowcode_Logged_In_Generator_Clicked_Create_And_Download',
  Flowcode_Logged_In_Generator_Clicked_Create_And_Print:
    'Flowcode_Logged_In_Generator_Clicked_Create_And_Print',
  Flowcode_Logged_In_Generator_Clicked_Create_And_Put_On_A_Flyer_Template:
    'Flowcode_Logged_In_Generator_Clicked_Create_And_Put_On_A_Flyer_Template',
  Flowcode_Logged_In_Generator_Clicked_Create_And_Put_On_A_Template:
    'Flowcode_Logged_In_Generator_Clicked_Create_And_Put_On_A_Template',
  Flowcode_Logged_In_Generator_Clicked_Create_And_Put_On_A_Business_Card:
    ' Flowcode_Logged_In_Generator_Clicked_Create_And_Put_On_A_Business_Card',
  Flowcode_Clicked_CompleteStateTab: 'Flowcode_Clicked_CompleteStateTab'
}
